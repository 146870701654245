
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import Inventory from '@/models/Inventory'
import VastTag from '@/models/VastTag'
import Widget from '@/components/Widget/Widget.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import AdunitScheduler from '@/models/AdunitScheduler'
import { clone as _clone } from 'lodash'
import LiveEvent from '@/models/LiveEvent'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import WebMessage from '@/models/WebMessage'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import {
  VastVersions, DeviceOptions, PaginationOptionsAll, AdserverOptions,
} from '@/models/interface/Common'
import dmaCodes from '@/data/dma_options'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import fields from './fields'
import ViewModel from '../../models/ViewModel'
import InventoryBatchEdit from './components/InventoryBatchEdit.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    FullCalendar,
    InventoryBatchEdit,
    SelectPicker,
    FormInput,
    AuditHistory,
  },
})
export default class InventoryHome extends ViewModel {
  @Ref() readonly audit_history!: AuditHistory

  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly batchForm!: HTMLFormElement

  @Ref() readonly searchInput!: SearchInput

  public target: Inventory = new Inventory()

  public target_scheduler: AdunitScheduler = new AdunitScheduler()

  public modal: any = {
    status: false,
    schedulers: false,
    enable_scheduler: false,
    disable_scheduler: false,
    history: false,
  }

  public query: string[] = ['is_not:archived', 'is:cim', 'is:top_level']

  public refresh_component: boolean = false

  public activeTab: number = 0

  public page_size: number = 25

  public sort_by: string = 'name'

  public sort_desc: boolean = false

  public ready: boolean = false

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public busy: boolean = false

  public fieldFilters: any = {
    remote_connection: '',
    is_programmatic: '',
    status: '',
  }

  public fields: Array<any> = []

  public history: Array<any> = []

  public history_records: number = 0

  public history_loading: boolean = false

  public ignore_tab: boolean = false

  public clipboard: string = ''

  // Selected rows
  public selected: string[] = []

  public inventories: Inventory[] = []

  public vast = new VastTag()

  public vast_generator_step: number = 1

  public get pagination_options() {
    return PaginationOptionsAll
  }

  public get result_name() {
    let names: any = ['results', 'results']
    return names[this.activeTab]
  }

  public get adserver_options() {
    return AdserverOptions
  }

  public get vast_versions() {
    return VastVersions
  }

  public get dma_options() {
    return dmaCodes
  }

  public get device_options() {
    return DeviceOptions
  }

  public get isForceDMAEnabled(): boolean {
    const system = getModule(SystemtModule)

    return system.env_vars.ad_server.system.allow_force_dma
  }

  public get isForceDeviceEnabled(): boolean {
    const system = getModule(SystemtModule)

    return system.env_vars.ad_server.system.allow_force_device
  }

  public calendarOptions(inventory: Inventory) {
    return {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, rrulePlugin],
      initialView: 'listMonth',
      nowIndicator: false,
      events: inventory.calendarEvents,
      headerToolbar: {
        start: 'prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      bootstrapFontAwesome: {
        close: ' la la-times',
        prev: ' la la-angle-left',
        next: ' la la-angle-right',
        prevYear: ' la la-angle-double-left',
        nextYear: ' la la-angle-double-right',
      },
      buttonText: {
        today: 'Today',
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listMonth: 'List',
      },
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      allDaySlot: false,
      weekends: true,
    }
  }

  public query_settings: any = {
    custom_fields: [
      {
        name: 'is:top_level',
        value: 'is:top_level',
      },
      {
        name: 'is_not:top_level',
        value: 'is_not:top_level',
      },
      {
        name: 'is:archived',
        value: 'is:archived',
      },
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
      },
      {
        name: 'is:active',
        value: 'is:active',
      },
      {
        name: 'is:inactive',
        value: 'is:inactive',
      },
      {
        name: 'is:orphan',
        value: 'is:orphan',
      },
      {
        name: 'is_not:orphan',
        value: 'is_not:orphan',
      },
      {
        name: 'is:cim',
        value: 'is:cim',
      },
      {
        name: 'is:gam',
        value: 'is:gam',
      },
      {
        name: 'is:fallback',
        value: 'is:fallback',
      },
      {
        name: 'is_not:fallback',
        value: 'is_not:fallback',
      },
      {
        name: 'has:fallback',
        value: 'has:fallback',
      },
      {
        name: 'has_not:fallback',
        value: 'has_not:fallback',
      },

      /* {
        name: 'has:schedulers',
        value: 'has:schedulers',
      },
      {
        name: 'has_not:schedulers',
        value: 'has_not:schedulers',
      }, */
    ],
  }

  public show_filter_helper: boolean = false

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public rows(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return Inventory.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.inventories = result.data
      return result.data
    })
  }

  public copyTag() {
    // @ts-ignore
    this.$refs.tag_clipboard.select()
    // @ts-ignore
    this.$refs.tag_clipboard.setSelectionRange(0, 99999)

    let status = true

    try {
      status = document.execCommand('copy')
    } catch (e) {
      status = false
    }

    if (status) {
      WebMessage.success('The Tag was copied to your clipboard!')
    } else {
      WebMessage.error(
        'Ops! We were not able to copy to your clipboard! Please copy the tag manually.',
      )
    }
  }

  public generateVast(inventory: Inventory) {
    this.vast = new VastTag()
    this.vast_generator_step = 1
    this.vast.inventory = inventory
    this.$bvModal.show('generate-tag')
  }

  public editInventory(inventory: Inventory) {
    this.$router.push(`/app/inventory/${inventory.id}`)
  }

  public createInventory() {
    let parent = ''
    for (const q of this.query) {
      if (q.includes('parent_id:')) {
        parent = q.replace('parent_id:', 'parent_id=')
      }
    }
    this.$router.push(`/app/inventory?${parent}`)
  }

  public toggleStatus(inventory: Inventory) {
    this.modal.status = true
    this.target = inventory
  }

  public confirmToggleStatus() {
    if (this.target.status) {
      this.target.deactivate().then(() => {
        this.dataTable.refresh()
      })
    } else {
      this.target.activate().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public toggleSchedulers(inventory: Inventory) {
    this.modal.schedulers = true
    this.target = inventory
  }

  public confirmToggleSchedulers() {
    if (this.target.disable_schedulers) {
      this.target.enableSchedulers().then(() => {
        this.dataTable.refresh()
      })
    } else {
      this.target.disableSchedulers().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public enableScheduler(inventory: Inventory, scheduler: AdunitScheduler) {
    this.modal.enable_scheduler = true
    this.target = inventory
    this.target_scheduler = scheduler
  }

  public confirmEnableScheduler() {
    this.target_scheduler.enableAdunit(this.target).then(() => {
      this.dataTable.refresh()
    })
  }

  public disableScheduler(inventory: Inventory, scheduler: AdunitScheduler) {
    this.modal.disable_scheduler = true
    this.target = inventory
    this.target_scheduler = scheduler
  }

  public confirmDisableScheduler() {
    this.target_scheduler.disableAdunit(this.target).then(() => {
      this.dataTable.refresh()
    })
  }

  public printGroupName(code: string | null) {
    const group = Inventory.module.type_options.find(o => o.value === code)
    return group ? group.name : code
  }

  public printLength(length: string | null) {
    return length ? `${length}s` : 'Any'
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('inventories').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'inventories',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'inventories',
      data: null,
    })
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = { remote_connection: '', is_programmatic: '', status: '' }
    this.query = ['is_not:archived', 'is:cim']
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.dataTable.refresh()
  }

  public mounted() {
    this.fields = fields
    // Fectch global Scheduler
    const global_scheduler = AdunitScheduler.module.data.find(s => s.is_global)
    if (!global_scheduler) {
      AdunitScheduler.get('global')
    }

    if (LiveEvent.module.data.length === 0) {
      LiveEvent.fetchAllValidEvents().then(r => {
        this.busy = false
      })
    } else {
      this.busy = false
    }

    this.loadFilters()
  }

  public loadHistoryBy(type = 'actions') {
    let condition = type !== 'actions'

    this.viewHistory(this.target, condition)
  }

  public loadAdunitHistories(inventory: Inventory) {
    this.ignore_tab = true
    this.activeTab = 0
    this.viewHistory(inventory, true)
    this.ignore_tab = false
  }

  public openScheduler(scheduler: AdunitScheduler) {
    this.$router.push(`/app/scheduler/${scheduler.id}`)
  }

  public viewHistory(inventory: Inventory, model = false) {
    setTimeout(() => {
      // If more tabs probably change this logic
      if (this.activeTab == 0) {
        this.modal.history = true

        this.target = inventory

        Vue.set(this.audit_history, 'query', ['model:adunit'])

        this.audit_history.setModel(inventory)

        Vue.set(this.audit_history, 'ready', true)

        Vue.set(this.audit_history, 'history_loading', false)

        this.audit_history.refresh()
      } else {
        this.history_loading = true
        this.modal.history = true
        this.target = inventory
        inventory
          .getHistory({
            page_size: this.page_size,
            page: this.page,
            order_by: 'created_at',
            order: 'desc',
            query: [],
          })
          .then((response: any) => {
            this.history = response.data.result.results
            this.history_records = response.data.result.records
            this.history_loading = false
          })
      }
    }, 200)
  }

  public viewChildren(inventory: Inventory | null = null) {
    const q = _clone(this.query).filter(
      (q: string) => !q.includes('parent_id:') && !q.includes(':top_level'),
    )
    q.push(inventory ? `parent_id:${inventory.id}` : 'is:top_level')

    if (inventory) {
      this.searchInput.addCustomTag({
        name: `parent:${inventory.name}`,
        value: `parent_id:${inventory.id}`,
      })
    }

    this.query = q
  }

  public batchConfirm() {
    this.loading = true
    // get the selected field
    let data = this.batchForm.buildData()

    let selected: Inventory[] = this.inventories.filter(i => this.selected.includes(i.id))

    selected = selected.map((inventory: Inventory) => {
      data.selected.forEach((prop: any) => {
        const path = prop.split('.')

        if (path.length === 1) {
          // @ts-ignore
          inventory[path[0]] = data.inventory[path[0]]
        } else if (path.length === 2) {
          // @ts-ignore
          inventory[path[0]][path[1]] = data.inventory[path[0]][path[1]]
        } else if (path.length === 3) {
          // @ts-ignore
          inventory[path[0]][path[1]][path[2]] = data.inventory[path[0]][path[1]][path[2]]
        }
      })

      return inventory
    })
    Inventory.batchUpdate(selected)
      .then(r => {
        this.loading = false
        this.$bvModal.hide('batch-edit')

        setTimeout(() => {
          this.dataTable.refresh()
        }, 1000)
      })
      .catch(e => {
        this.loading = false
      })
  }

  public batchEdit() {
    if (this.selected.length === 0) {
      WebMessage.error('Please select at least one Ad Unit')
      return
    }
    this.$bvModal.show('batch-edit')
  }

  public copy(val: string) {
    this.clipboard = val
    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'text')

    setTimeout(() => {
      // @ts-ignore
      this.$refs.clipboard.select()

      let status = false

      try {
        status = document.execCommand('copy')
      } catch (e) {
        status = false
      }

      // @ts-ignore
      this.$refs.clipboard.setAttribute('type', 'hidden')

      if (status) {
        WebMessage.success('ID was copied to your clipboard!')
      } else {
        WebMessage.error(
          `Ops! We were not able to copy to your clipboard, please copy: ${this.clipboard}`,
        )
      }
    }, 100)
  }
}
