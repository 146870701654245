import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Inventory from '@/models/Inventory'
import { startCase } from 'lodash'
import { RemoteConnections } from '@/models/interface/Common'
import SelectOption from '@/models/interface/SelectOption'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'admanager_id',
    label: 'GAM ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle',
  },
  {
    key: 'device',
    label: 'Devices',
    sortable: false,
    show: false,
    tdClass: 'align-middle',
    formatter: (_: string, __: string, inventory: Inventory) =>
      (inventory.devices.length > 0 ? inventory.devices.join(', ') : 'N/A'),
  },
  {
    key: 'live',
    label: 'Live',
    sortable: true,
    show: false,
    type: 'boolean',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'length',
    label: 'AdUnit Length',
    sortable: true,
    show: false,
    tdClass: 'align-middle text-center',
    formatter: (_: string, __: string, inventory: Inventory) =>
      (length && length >= 15 ? `${length}s` : 'Any'),
  },
  {
    key: 'adserver',
    label: 'Adserver',
    sortable: true,
    show: true,
    type: 'badge_uppercase',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) => (inventory.adserver === 'cim' ? 'primary' : 'info'),
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    show: true,
    type: 'currency',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'group',
    label: 'Group',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
    formatter: (value: string) => {
      const group = Inventory.module.type_options.find(o => o.value === value)
      return group ? group.name : value
    },
  },
  {
    key: 'publisher',
    label: 'Publisher',
    type: 'object',
    object: {
      key: 'publisher',
      property: 'name',
    },
    sortable: true,
    show: true,
    tdClass: 'align-middle',
    formatter: (value: string) => value ?? '-',
  },
  {
    key: 'is_fallback',
    label: 'Type',
    sortable: true,
    show: true,
    type: 'badge',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) => (inventory.is_fallback ? 'info' : 'success'),
    formatter: (_: string, __: string, inventory: Inventory) =>
      (inventory.is_fallback ? 'Fallback' : 'Default'),
  },
  {
    key: 'fallback_inventory_id',
    label: 'Has Fallback',
    sortable: true,
    show: true,
    type: 'badge',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) =>
      (inventory.is_fallback ? 'warning' : inventory.fallback_inventory_id ? 'success' : 'danger'),
    formatter: (_: string, __: string, inventory: Inventory) =>
      (inventory.is_fallback ? 'N/A' : inventory.fallback_inventory_id ? 'Yes' : 'No'),
  },
  // {
  //   key: 'status',
  //   label: 'Status',
  //   sortable: true,
  //   show: true,
  //   type: 'badge',
  //   tdClass: 'align-middle text-center',
  //   color: (inventory: Inventory) => (inventory.status ? 'success' : 'danger'),
  //   formatter: (_: string, __: string, inventory: Inventory) =>
  //     inventory.status ? 'active' : 'inactive',
  // },

  {
    key: 'schedulers',
    label: 'Schedulers',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    formatter: (_: any, __: any, inventory: Inventory) =>
      (inventory.disable_schedulers
        ? 'Disabled'
        : inventory.allSchedulers.length === 0
          ? 'No'
          : `${inventory.active_schedulers.length}/${inventory.allSchedulers.length}`),
    color: (inventory: Inventory) =>
      (inventory.allSchedulers.length === 0
        ? 'danger'
        : inventory.allSchedulers.length > inventory.active_schedulers.length
          ? 'warning'
          : 'info'),
  },
  {
    key: 'remote_connection',
    label: 'Remote',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    formatter: (_: any, __: any, inventory: Inventory) => {
      if (inventory.remote_connection) {
        return inventory.remote_connection.replaceAll('_', ' ').toUpperCase()
      }
      return 'No'
    },
    color: (inventory: Inventory) => {
      if (inventory.remote_connection) {
        return 'info'
      }
      return 'danger'
    },
    filter_type: 'picker',
    options: [new SelectOption('Remote', ''), ...RemoteConnections],
  },
  {
    key: 'is_programmatic',
    label: 'Programmatic',
    sortable: true,
    show: false,
    type: 'badge',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) => (inventory.is_programmatic ? 'success' : 'danger'),
    formatter: (_: string, __: string, inventory: Inventory) =>
      (inventory.is_programmatic ? 'Yes' : 'No'),
    filter_type: 'picker',
    options: [
      new SelectOption('Programmatic', ''),
      new SelectOption('Yes', '1'),
      new SelectOption('No', '0'),
    ],
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) => (inventory.status ? 'success' : 'danger'),
    formatter: (_: string, __: string, inventory: Inventory) =>
      (inventory.status ? 'active' : 'inactive'),
    filter_type: 'picker',
    options: [
      new SelectOption('Status', ''),
      new SelectOption('Active', '1'),
      new SelectOption('Inactive', '0'),
    ],
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'history_notes_count',
    label: 'Audit notes',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    color: (inventory: Inventory) => 'info',
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'align-middle text-center',
    class: 'text-center align-middle',
    thStyle: { width: '200px' },
    actions: [
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
        top_level: true,
      },
      {
        icon: 'sitemap',
        title: 'View child Ad Units',
        event: 'viewChildren',
        top_level: true,
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: (i: Inventory) => user && user.can('inventory', 'edit'),
      },
      {
        icon: 'pause',
        title: 'Deactivate',
        event: 'toggleStatus',
        top_level: true,
        show: (i: Inventory) => i.status && user && user.can('inventory', 'edit'),
      },
      {
        icon: 'play',
        title: 'Activate',
        event: 'toggleStatus',
        top_level: true,
        show: (i: Inventory) => !i.status && user && user.can('inventory', 'edit'),
      },
      {
        icon: 'eye',
        title: 'View',
        event: 'view',
        top_level: true,
      },
      {
        customIcon: 'stopwatch-slash',
        title: 'Disable Schedulers',
        event: 'toggleSchedulers',
        show: (i: Inventory) => !i.disable_schedulers && user && user.can('inventory', 'edit'),
      },
      {
        customIcon: 'stopwatch',
        title: 'Enable Schedulers',
        event: 'toggleSchedulers',
        show: (i: Inventory) => i.disable_schedulers && user && user.can('inventory', 'edit'),
      },
      {
        icon: 'code',
        title: 'Generate VAST Tag',
        event: 'generateVast',
        show: (i: Inventory) => i.adserver === 'cim' && !i.is_fallback && i.parent_id !== null,
      },
      // {
      //   icon: 'history',
      //   title: 'View Action History',
      //   event: 'viewHistory',
      // },
      {
        icon: 'history',
        title: 'History',
        event: 'adunitHistory',
      },
    ],
  },
]
