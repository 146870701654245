
import { Component, Vue, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InventoryForm from './components/InventoryForm.vue'

@Component({
  components: {
    Widget,
    InventoryForm,
  },
})
export default class InventoryEdit extends Vue {
  @Prop()
  public id!: string;

  public title: string = 'Create Ad Unit';

  public created() {
    if (this.id) {
      this.title = 'Edit Ad Unit'
    }
  }
}
