
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import Inventory from '@/models/Inventory'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { Ref } from 'vue-property-decorator'
import SpecialFeaturesPicker from '@/components/MediaPlanBuilder/SpecialFeaturesPicker.vue'
import { CategoryAuthorityOptions, CategoryAuthorityVersionOptions } from '@/models/interface/Common'
import { clone } from 'lodash'
import object_maps from '../object_maps'

@Component({
  components: {
    FormInput,
    SelectPicker,
    CompanyPicker,
    CheckboxInput,
    IconAction,
    SpecialFeaturesPicker,
  },
})
export default class InventoryBatchEdit extends ViewModel {
  @Ref() dataTable!: any

  public selected: any = []

  public inventory: Inventory = new Inventory()

  public get authority_options() {
    return CategoryAuthorityOptions
  }

  public get authority_version_options() {
    if (!CategoryAuthorityVersionOptions[this.inventory.category_authority]) {
      return []
    }
    return CategoryAuthorityVersionOptions[this.inventory.category_authority]
  }

  public temp_inventory_special_rate: any = {
    type: null,
    model: 'surcharge',
    value: 0,
    priority: 1,
  }

  public special_rates_fields: any = [
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'model',
      label: 'Model',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'value',
      label: 'Value',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'priority',
      label: 'Priority',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
    {
      key: 'action',
      label: 'Actions',
      sortable: true,
      show: false,
      tdClass: 'align-middle text-center',
      thClass: 'text-center',
    },
  ]

  public status_options = [
    {
      value: true,
      name: 'Active',
    },
    {
      value: false,
      name: 'Inactive',
    },
  ]

  public apply_to_children_rate = [
    {
      value: true,
      name: 'On',
    },
    {
      value: false,
      name: 'Off',
    },
  ]

  public bool_options = [
    {
      value: false,
      name: 'Yes',
    },
    {
      value: true,
      name: 'No',
    },
  ]

  public model_options = [
    {
      value: 'surcharge',
      name: 'Surcharge',
    },
    {
      value: 'base',
      name: 'Base',
    },
    {
      value: 'absolute',
      name: 'Absolute',
    },
  ]

  public get features_options(): any {
    return object_maps.features_options
  }

  public get masks() {
    return {
      currencyMask,
      percentageMask,
    }
  }

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public addSpecialRate() {
    this.inventory.special_rates.push({
      type: null,
      model: 'surcharge',
      value: 0,
      priority: 1,
    })

    setTimeout(() => {
      this.dataTable.refresh()
    }, 1000)
  }

  public removeSpecialRate(index: number) {
    this.inventory.special_rates.splice(index, 1)
    setTimeout(() => {
      this.dataTable.refresh()
    }, 500)
  }

  public buildData() {
    this.inventory.special_rates = this.inventory.special_rates.map((rate: any) => {
      let n = rate.value.replaceAll('$', '')
      rate.value = Number(n)
      return rate
    })

    const selected = clone(this.selected)

    if (selected.includes('category_authority') && !selected.includes('category_authority_version')) {
      selected.push('category_authority_version')
    }

    return {
      selected,
      inventory: this.inventory,
    }
  }
}
