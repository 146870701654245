import Inventory from './Inventory'

export default class VastTag {
  // @ts-ignore
  private baseUrl: string = process.env.VUE_APP_BASE_ADSERVER_URL

  public version: string = '3.0'

  public key_value: string = ''

  public map_code: string = ''

  public min_duration: number = 0

  public max_duration: number = 0

  public force_dma: number = 0

  public force_device: string = ''

  public target_adserver: string = 'gam'

  public inventory = new Inventory()

  public get extra_params(): string {
    if (this.target_adserver === 'freewheel') {
      return 'cb=#{ad.ref.random}&aid=#{request.deviceId}&is_lat=#{COPPA}&app_bundle_id=#{site.attribute("appBundle")}&app_name=#{site.attribute("appName")}'
    }
    return 'cb=%%CACHEBUSTER%%&aid=%%ADVERTISING_IDENTIFIER_PLAIN%%&is_lat=%%ADVERTISING_IDENTIFIER_IS_LAT%%&app_bundle_id=%%APP_BUNDLE_ID%%&app_name=%%APP_NAME%%'
  }

  public get url(): string {
    let params = ''
    // &ip=%%USER_IP%%&ua=%%SOURCE_USER_AGENT%%&ip=%%SOURCE_IP%%

    if (this.key_value !== '') {
      params += `&kv=${this.key_value}`
    }

    if (this.map_code !== '') {
      let code = this.map_code.replaceAll(' ', '_').toLowerCase()
      params += `&am=${code}`
    }

    if (this.min_duration > 0) {
      params += `&mind=${this.min_duration}`
    }

    if (this.max_duration > 0) {
      params += `&maxd=${this.max_duration}`
    }

    if (this.force_dma && this.force_dma > 0) {
      params += `&fdma=${this.force_dma}`
    }

    if (this.force_device && this.force_device !== '') {
      params += `&fdt=${this.force_device}`
    }

    if (params !== '') {
      params += '&'
    }

    // if params starts with & remove it
    if (params.startsWith('&')) {
      params = params.substring(1)
    }

    return `${this.baseUrl}vast/${this.version}/video/${this.inventory.id}?${params}${this.extra_params}`
  }

  public get clean_url(): string {
    let url = `${this.baseUrl}vast/${this.version}/${this.inventory.id}?`

    if (this.key_value !== '') {
      url += `&kv=${this.key_value}`
    }

    if (this.map_code !== '') {
      let code = this.map_code.replaceAll(' ', '_').toLowerCase()
      url += `&am=${code}`
    }

    if (this.min_duration > 0) {
      url += `&mind=${this.min_duration}`
    }

    if (this.max_duration > 0) {
      url += `&maxd=${this.max_duration}`
    }

    if (this.force_dma && this.force_dma > 0) {
      url += `&fdma=${this.force_dma}`
    }

    if (this.force_device && this.force_device !== '') {
      url += `&fdt=${this.force_device}`
    }

    return url
  }

  public inspect(mode: string = 'google') {
    const url = this.clean_url

    if (!url) return

    if (mode == 'google') {
      window.open(
        `https://googleads.github.io/googleads-ima-html5/vsi/?tag=${encodeURIComponent(
          encodeURIComponent(url),
        )}`,
        '_blank',
      )
    } else {
      window.open(
        `https://flowplayer.com/developers/tools/ad-tester?ad=0&custom=${encodeURIComponent(
          url,
        )}&release_channel=stable`,
        '_blank',
      )
    }
  }
}
